import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
import * as gongju from '@/libs/gongju';
import Decimal from 'decimal.js';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Popover } from 'vant';
import { Circle } from 'vant';
import * as echarts from 'echarts';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      currentRate: 0,
      search_value: '',
      active: 0,
      news: [],
      lang_list: [],
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      showPopover: false,
      all: [{
        top: -0.7,
        class: "1"
      }],
      count: 10,
      jizi: [],
      text: "7/10",
      chken: {},
      currentRate: 0,
      currentRate1: 0,
      ji: {
        ce1: 0,
        ce2: 100
      },
      dao: [],
      app_root: api.base(),
      data: [],
      list: [],
      loading: false,
      finished: false,
      page: 1,
      head_bg: ""
    };
  },
  created: function () {
    let _this = this;
    let init = 0;
    this.head_bg = local.readInfo('head_bg');
    //  console.log(this.all)
    _this.get_imdex_data();
  },
  mounted() {},
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      api.all('/api/user/phoc_list', {
        page: this.page,
        type: 1
      }, (err, data) => {
        this.loading = false;
        if (!err && data.code === 1) {
          this.page = this.page + 1;
          for (let i = 0; i < data.data.dizhi.length; i++) {
            this.list.push(data.data.dizhi[i]);
          }
          if (this.list.length >= data.data.count) {
            this.finished = true;
          }
        } else {
          console.log(data);
        }
      });
    },
    initChart() {
      const chartDom = this.$refs.chartContainer;
      const myChart = echarts.init(chartDom);
      const option = {
        title: {
          text: this.lang.t18,
          subtext: this.lang.t19,
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: [this.lang.t21, this.lang.t22, this.lang.t20]
        },
        series: [{
          name: this.lang.t23,
          type: 'pie',
          radius: '55%',
          data: this.data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      };
      myChart.setOption(option);
    },
    left() {
      this.$refs.swiper.prev();
    },
    right() {
      this.$refs.swiper.next();
    },
    onClickLeft() {
      api.chat(0);
    },
    //切换语言
    select(e) {
      local.saveInfo('setting_lang', e);
      window.location.reload();
    },
    back() {
      window.history.back();
    },
    get_imdex_data: function () {
      api.all('/api/index/hoc', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.user = data.data.user;
          this.dao = data.data.dao;
          this.text = data.data.dao.bai + "%";
          this.data = data.data.data;
          this.initChart();
        } else {
          console.log(data);
          //  if(data&&'msg' in data){
          //    Toast(data.msg)
          //  }
          //this.block = false
        }
      });
    },
    goto: function (name) {
      let _this = this;
      _this.$router.push({
        name: name,
        query: {
          id: 1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    },
    to_dao: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'detail',
        query: {
          id: id1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部  
      });
    },
    news_list: function () {
      let _this = this;
      _this.$router.push({
        name: 'ac_list',
        query: {
          id: 0
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部  
      });
    }
  }
};